<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-tree</v-icon> Productos</h1><br>
        <v-layout>
          <v-flex xs3>
            <v-select
              label="Tipo de producto"
              v-model="producto"
              outlined
              :items="productos"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              label="Nombre del producto"
              v-model="nombre"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              label="Categoría del producto"
              v-model="categoria"
              outlined
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs1>
            <v-btn v-on:click="insert" text class="btnMini">
              <v-icon large>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="editarProducto(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="eliminarProducto(item.id)">
            mdi-delete
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import genericReload from '../components/genericReload'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
export default {
  name: 'Productos',
  data () {
    return {
      producto: 'Aceituna',
      productos: ['Aceituna', 'Cascara', 'Frutales'],
      nombre: '',
      categoria: '',
      headersResumen: [
        {
          text: 'Tipo de producto',
          align: 'start',
          sortable: true,
          value: 'tipo',
        },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoría / Calidad', value: 'categoria' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.campanaInicio = new Date().getFullYear();
    this.campanaFin = new Date().getFullYear()+1;
    this.read();
  },
  methods:{
    campanaInicioChange(){
      this.campanaFin = parseInt(this.campanaInicio)+1;
    },
    campanaFinChange(){
      this.campanaInicio = parseInt(this.campanaFin)-1;
    },
    async insert(){
      if(this.nombre != '' && this.categoria != ''){
        const data = {
          nombre: this.nombre+" - "+this.categoria,
          producto: this.producto,
        };

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.$store.state.auth
        };

        const response = await fetch(
          `${BASE_URL}/categoria`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        switch (response.status) {
          case 200:
            this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
            this.read();
            this.categoria = '';
            this.nombre = '';
            break;
          default:
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
            break;
        }
      }
    },
    async read(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        let nomcat = '';
        for(let i = 0; i<json.body.length; i++){
          nomcat = json.body[i].nombre;
          let [nom, cat] = nomcat.split(" - ");
          this.resumen.push({id: json.body[i].id, tipo: json.body[i].producto, nombre: nom, categoria: cat});
        }
        await this.$store.dispatch('getProductos');
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    async eliminarProducto(id){
      this.$refs.dialog.setNew("Eliminar producto", "¿Estás seguro de querer eliminar este producto? Debes de tener en cuenta que puede producirse una perdida de datos si este producto ya ha sido asignado previamente en la fijación de precios.", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/categoria/${id}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Producto eliminado correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Producto no eliminado", "Cerrar", "red");
              break;
          }
        }
      })
    },
    async editarProducto(item){
      this.$refs.dialog.setNew("Editar producto", "", "editProduct", [item.tipo, item.nombre, item.categoria]).then(async(confirm) => {
        if(confirm.response){
          const data = {
            nombre: confirm.nombre+" - "+confirm.categoria,
            producto: confirm.producto,
          };
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/categoria/${item.id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Producto editado correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Producto no editado", "Cerrar", "red");
              break;
          }
        }
      })
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>